import FloatingItems from "../components/FloatingItems";
import {useRef} from "react";
import Navbar from "../components/Navbar";
import ProjectCard from "../components/ProjectCard";
import HeroIMG from '../assets/images/hero-img.png';
import HeroBlack from '../assets/images/hero-black.png';
import MayowaIMG from '../assets/images/mayowa-abiodun.jpeg';
import MakindeIMG from '../assets/images/makinde-timileyin.jpeg';
import UserIMG from '../assets/images/user.png';
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCoverflow } from 'swiper/modules';
import allProjects from '../portfolio-data.json';

import SoftwareDevImg from '../assets/images/progamming.png';
import MobileDevImg from '../assets/images/mobile-development.png';
import WebDevImg from '../assets/images/web-development.png';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Link} from "react-router-dom";

function App() {
  const shuffuledProjects = allProjects.sort(() => 0.5 - Math.random());
  let featuredProjects = shuffuledProjects.slice(0, 3);

  const heroRef = useRef(null);

  const item = {
    bubbleCount: 15,
    bubbleDuration: 40,
    bubbleAverageRadius: 50,
    bubbleColorAssorted: true,
    bubbleColor: '#FFFFFF',
    bubbleOpacity: 0.5,
    bubbleInterval: 0,
    infiniteBubbles: false
  }

  return (
    <div className="App">
      <Navbar />
      <div className="hero-container">

        <div className="hero" ref={heroRef}>
          <div className="glass-card">
            <div className="glass-card-text">
              Hi <br />
              My Name is
              <div className="name">Adeyinka</div>
              Web Developer
            </div>
          </div>
          <div className="hero-img">
            <div className="hero-img-bg"></div>
            <img src={HeroIMG} alt="" />
          </div>
        </div>
        <FloatingItems item={item} containerRef={heroRef} />
      </div>
      <div className="recent">
        <div className="recent-head">
          About Me
        </div>
        <div className="about">
          <div className="about-image">
            <img src={HeroBlack} alt="" className="about-image-img"/>
          </div>
          <div className="about-text">
            <p>
              A Computer Science graduate and software developer with over five years of professional experience designing, developing, and maintaining applications.
            </p>
            <p>
              I have worked on projects ranging from simple landing pages to dashboards, mobile applications, enterprise multi-tenant web applications, browser-based graphics web applications and Core Banking Systems.
            </p>
            <p>
              I use my expertise to breathe life into softwares by creating functional and interactive systems that follows best practices.
            </p>
            <p>
              I'm a certified Mobile web Specialist and an expert in responsive design and performance optimization. I pride myself in consistently delivering
              projects on time and within budget while upholding a high standard of quality and professionalism.
            </p>
            <div className="about-contact">
              <button className="recent-contact-btn btn-primary">
                Contact Me
              </button>
              <a
                className="btn-text"
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1zkPxMODOcG0_wh_CB4Hh-XS6QwWV_HLE/view?usp=sharing"
              >
                Download Resume
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="recent">
        <div className="recent-head">
          My Expertise
        </div>
        <div className="slida">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="expertise">
                <div className="expertise-head">
                  <div className="expertise-head-img">
                    <img src={SoftwareDevImg} alt=""/>
                  </div>
                  <div className="expertise-head-text">
                    Software Development
                  </div>
                </div>
                <div className="expertise-text">
                  <div className="expertise-text-main">
                  Experienced in both functional and OOP: Dart, Python, Java, JavaScript, TypeScript.
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expertise">
                <div className="expertise-head">
                  <div className="expertise-head-img">
                    <img src={WebDevImg} alt=""/>
                  </div>
                  <div className="expertise-head-text">
                    Web Development
                  </div>
                </div>
                <div className="expertise-text">
                  <div className="expertise-text-main">
                    A certified Mobile web Specialist, and I'm an expert in responsive design, performance optimization, and best practices.
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expertise">
                <div className="expertise-head">
                  <div className="expertise-head-img">
                    <img src={MobileDevImg} alt=""/>
                  </div>
                  <div className="expertise-head-text">
                    Mobile Development
                  </div>
                </div>
                <div className="expertise-text">
                  <div className="expertise-text-main">
                    Skilled in developing hybrid mobile apps and cross-platform solutions using React Native, Vue/React Iconic and Flutter framework.
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="recent">
        <div className="recent-head">
          Featured Projects
        </div>
        <div className="project portfolio-items">
          {
            featuredProjects?.map((project, index)=>(
              <ProjectCard project={project} key={index} />
            ))
          }
        </div>
        <div className="recent-btn">
          <Link to="/portfolio" className="btn-primary">
            View all Projects
          </Link>
        </div>
      </div>
      <div className="testimonial">
        <div className="testimonial-head">
          Testimonials
        </div>
        {/*<Testimonials />*/}

        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          // navigation={true}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="testimonial-content">
              <div className="testimonial-text">
                <p>
                  I wholeheartedly recommend Adeyinka as a software engineer. During our time working together, Adeyinka consistently demonstrated a strong commitment to learning and a genuine passion for software development. He is very good at every language or technology we used as a team, which made him a very valuable asset to the us.
                </p>
                <p>
                  His positive attitude and willingness to collaborate with the team will make him a valuable member of any organization."
                </p>
              </div>
              <div className="testimonial-author">
                <div className="testimonial-author-image">
                  <img src={MayowaIMG} alt="" className="testimonial-author-image-img"/>
                </div>
                <div className="testimonial-author-info">
                  <div className="testimonial-author-info-head">
                    Mayowa Abiodun
                  </div>
                  <div className="testimonial-author-info-text">
                    Senior Software Engineer & Consultant
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-content">
              <div className="testimonial-text">
                <p>
                  Adeyinka is an experienced Software Engineer with expertise in Nodejs, React, React native and DevOps. I worked along side him for 5 plus months and as a colleague, I was impressed with his work ethic and problem solving skills. He is a hard working and talented engineer who is always ready to go the extra mile to deliver quality work. He'll be a great asset to any company looking for a great worker.
                </p>
              </div>
              <div className="testimonial-author">
                <div className="testimonial-author-image">
                  <img src={MakindeIMG} alt="" className="testimonial-author-image-img"/>
                </div>
                <div className="testimonial-author-info">
                  <div className="testimonial-author-info-head">
                    Makinde Timileyin
                  </div>
                  <div className="testimonial-author-info-text">
                    Senior Engineer
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-content">

              <div className="testimonial-text">
                <p>
                  Adeyinka is a remarkable software engineer with potential, we worked together at Poket Finance where he was involved in developing our cross-platform mobile app using Java, ReactNative, Typescript and also worked on the backend with Node.js, MongoDB. He is fast at learning new technologies and posses great communication skills. He is definitely going to be a great addition to your team. Keep it up Adeyinka!
                </p>
              </div>
              <div className="testimonial-author">
                <div className="testimonial-author-image">
                  <img src={UserIMG} alt="" className="testimonial-author-image-img"/>
                </div>
                <div className="testimonial-author-info">
                  <div className="testimonial-author-info-head">
                    David
                  </div>
                  <div className="testimonial-author-info-text">
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <Footer />
    </div>
  );
}

export default App;
